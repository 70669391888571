/* global $jQueryModern, simpleStorage */
/* exported volModal */

var volModal = (function($, ss) {

    'use strict';

    var $document = $(document);
    var $body = $('body');
    var $modalMask = $('<a class="modal-mask" href="#"></a>');

    // get path from script tag and remove file name
    var path = (function() {
        var src = $('#modal-script').attr('src');
        return src.substring(0, src.lastIndexOf('/') + 1);
    })();

    var options = {
        wait: 604800000,
        development: false,
        alwaysLoadAssets: false,
        showOnMobile: true,
        toggleSelector: 'a[href="#toggleModal"]',
        closeSelector: 'a[href="#closeModal"], .modal-mask',
        closeOnEscape: false,
        preventAfterUnload: true
    };

    function setLocalStorage() {
        // set localStorage var to keep track of elapsed time
        ss.set('preventModal', true);
        // clear after options.wait timeout
        ss.setTTL('preventModal', options.wait);
    }

    function closeOnEscape(e) {
        if (e.keyCode === 27) {
            closeModal(e);
        }
    }

    function closeModal(e) {
        e.preventDefault();

        $body.removeClass('modal-active');
        $document.unbind('keyup', closeOnEscape);

        setLocalStorage();
    }

    function openModal(e) {
        if (e) { e.preventDefault(); }

        $body.addClass('modal-active');

        if (options.closeOnEscape) {
            $document.keyup(closeOnEscape);
        }
    }

    function toggleModal(e) {
        if ($body.hasClass('modal-active')) {
            closeModal(e);
        } else {
            openModal(e);
        }
    }

    function loadModal(data) {
        $body.append($modalMask);
        $(data).appendTo($body);

        // set toggle/close handler
        $document.on('click', options.toggleSelector, toggleModal);
        $document.on('click', options.closeSelector, closeModal);

        if (!options.development && ss.get('preventModal')) {
            // user has already seen the modal
            return;
        }

        // set localStorage var when navigating away from page
        if (options.preventAfterUnload) {
            $(window).unload(setLocalStorage);            
        }
        
        // small timeout allows initial animation to happen
        setTimeout(openModal, 100);
    }

    // get modal html and load modal via callback
    function getModalHTML() {
        $.get(path + '/modal.html', loadModal);
    }

    // adds css link to head
    function getModalStyle() {
        $('head').append('<link rel="stylesheet" type="text/css" href="' + path + '/modal.css">');
    }

    function init(userOptions) {
        userOptions = userOptions || {};

        // merge user options with defaults
        options = $.extend(options, userOptions);

        if (!options.showOnMobile && $(window).width() < 768) {
            // don't load on mobile
            return;
        }

        if (!options.development && !options.alwaysLoadAssets && ss.get('preventModal')) {
            // don't load if no toggle and preventModal is true
            return;
        }

        // ajax in needed files
        getModalHTML();
        getModalStyle();
    }

    return {
        init: init
    };

})($jQueryModern, simpleStorage);
